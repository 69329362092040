// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  awsconfig: {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:0c7b3de7-75de-48f0-bd84-6572d67b2fa1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_tWS5gPtB4",
    aws_user_pools_web_client_id: "4gi1p9cokn0tppmdjc7kk8jvgi",
    oauth: {
        domain: "g2-land-prod.auth.us-east-1.amazoncognito.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://localhost:4200/",
        redirectSignOut: "https://localhost:4200/",
        responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS",

    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint: "https://5a5gd36g6rgthh7bqtgmtzuzta.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_appid: "g3dr2pjfbzai5l5tyvtdjqebn4",
    image_path_url: "assets-land-prod.g2landauctions.com",
    buyer_portal_path_url: "app.g2landauctions.com"
  },
  salemanagementcontactdefault: {
    name: "Jeremy Haag",
    email: "jeremy@g2landauctions.com",
    phone: "(406) 833-0117"
  }
};
